import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Form, Modal, ModalHeader, ModalBody, Input, Label, Col, Row, Button } from 'reactstrap';

class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedVal: "",
        trackingId: ""
    };
    this.toggle = this.toggle.bind(this);
}
toggle = () => {
    this.props.clicked()
};

handleDropdown = (e) => {

    this.setState({ selectedVal: e })
}



render() {
    return (
        <Modal size="sm" isOpen={true} style={{ height: '150px' }} scrollable={'true'}>
            <ModalHeader toggle={this.toggle}>
                Info
            </ModalHeader>
            <ModalBody>
                <Row style={{ marginTop: '6px' }}>
                    <Col xl={6} lg={6}>Version: 25.03</Col>
                </Row>
                <Row >
                    <Col xl={7} lg={6}>Build Date: 03/18/2025</Col>
                </Row>

            </ModalBody>
        </Modal >
    )
}
}

export default InfoModal;
